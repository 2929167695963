import React from 'react';
import { classList } from '../../helpers/classList';
import { SidebarItems } from './SidebarItems';
import { showModal } from '../../helpers/modal';
import ServerSelect from '../../components/ServerSelect';
import { DarkModeSwitch } from '../../components/DarkModeSwitch';
import { queryVanlo } from '../../resources/query';

export const Sidebar = (props) => (
  <div className={classList('v-sidebar', props.testMode && 'v-testmode')}>
    <SidebarHeader />
    <SidebarItems {...props} />
    <ServerSelect />
    <DarkModeSwitch />
    <SidebarFooter />
  </div>
);

const SidebarHeader = () => (
  <div className="v-sidebar-header">
    <a href="https://vanlo.com" target="about:blank">
      VANLO
    </a>
  </div>
);

export async function showStatic(page, Controls) {
  queryVanlo(`/dashboard/${page}.json`).then(({ title, body }) => {
    showModal({
      title,
      extra:     { className: 'v-iframe-container' },
      component: () => (
        <div className="v-iframe-window">
          <div dangerouslySetInnerHTML={{ __html: body }} />
          {Controls && <Controls />}
        </div>
      ),
      closeButton: !Controls,
    });
  });
}

const SidebarFooter = () => (
  <div className="v-footer">
    <div className="v-footer-links v-center">
      <div className="v-link v-footer-terms" onClick={() => showStatic('terms')}>
        Terms & Conditions
      </div>
      <div className="v-link v-footer-privacy" onClick={() => showStatic('privacy')}>
        Privacy Policy
      </div>
    </div>
    <div className="v-footer-copyright">&copy; 2022 Vanlo, Inc. All rights reserved.</div>
  </div>
);
