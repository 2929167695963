import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../../helpers/classList';
import { Grid, Grid1, Grid2 } from '../../components/Grids';
import { BalanceAmount } from './BalanceAmount';
import { Button } from '../../components/Button';
import { IconExportDoc, IconBank } from '../../helpers/icons';
import { TableFiltersComponent } from '../../components/TableFiltersComponent';
import { Table } from '../../components/Table';
import { Textbox } from '../../components/Textbox';
import { ExternalTransactionResource, UserSettingsResource } from '../../resources';
import { getDetailView, getTableConfig } from './config';
import { Pagination } from '../../components/Pagination';
import { showErrorMessage, showMessage, showModal, showBusyMessage } from '../../helpers/modal';
import { PaymentLogModal } from './PaymentLogModal';
import { BankAccount } from '../PaymentMethods/BankAccount';
import { PlaidCheckoutForm } from '../PaymentMethods/plait/PlaidCheckoutForm';
import { Card } from '../../components/Card';
import { Title } from '../../components/Title';
import { Checkbox } from '../../components/Checkbox';

class ExternalBalances extends React.Component {
  state = { ...ExternalTransactionResource.filters };

  exportCSV() {
    ExternalTransactionResource.exportCSV(this.state.reportsEmail).then(({ success, message }) => {
      if (success) {
        showMessage(<div className="v-body-content">{message}</div>, null, true);
      } else {
        showErrorMessage(message);
      }
    });
  }

  emailChanged(event) {
    this.setState({ reportsEmail: event.target.value });
  }

  submit(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.exportCSV();
    }
  }

  setPage(page) {
    if (page !== this.state.page) {
      this.setState({ page });
      ExternalTransactionResource.setFilter('page', page);
      ExternalTransactionResource.list();
    }
  }

  showPayment(data) {
    showModal({
      title:     `Payment #${data.id}`,
      component: PaymentLogModal,
      payload:   { data: getDetailView(data) },
    });
  }

  approve(id) {
    const promise = ExternalTransactionResource.approve(id);

    const { billing_enabled } = this.props.externalBalance.balance;

    if (billing_enabled) {
      showBusyMessage('Approving...', promise);
      promise.then((r) => {
        if (r.success) {
          ExternalTransactionResource.list();
        } else {
          showErrorMessage(r.message);
        }
      });
    } else {
      showErrorMessage('Your business account is pending verification');

      setTimeout(() => (window.location.href = '/shipments'), 2000);
    }
  }

  changeAutoApprove() {
    const current = this.props.userSettings.auto_approve_payouts.value;
    UserSettingsResource.update({ settings: { auto_approve_payouts: !current } }, 'Updating settings...');
  }

  render() {
    const {
      user,
      userSettings,
      externalBalance: { balance },
      transactions: { transactions, total_count },
      payout_bank_account,
    } = this.props;
    const { page, per } = this.state;
    let payout_bank_account_tag;
    if (payout_bank_account) {
      payout_bank_account_tag = <BankAccount account={payout_bank_account} />;
    } else {
      payout_bank_account_tag = <PlaidCheckoutForm external bankAccountType="PayoutBankAccount" />;
    }

    return (
      <div className={classList('v-awaiting-shipments', 'v-page')}>
        <Title title="External Incentives" />
        <Grid>
          <Grid1 className="v-margin-right-3rem">
            <BalanceAmount balance={balance} />
          </Grid1>
          <Grid2>
            <Card title="PAYOUT BANK ACCOUNT" icon={IconBank}>
              {payout_bank_account_tag}
              {payout_bank_account && (
                <Checkbox
                  onChange={this.changeAutoApprove.bind(this)}
                  checked={userSettings.auto_approve_payouts.value}
                  name="auto_approve_payouts"
                  label="Automatically approve incentive reports"
                />
              )}
            </Card>
          </Grid2>
        </Grid>
        <div className="v-hr v-hr-2" />

        <Grid className="v-center-left">
          <Grid1 className="v-center-left">
            <div className="v-title">External Payment Logs</div>
          </Grid1>
          <Grid1 className="v-center-right">
            <form autoComplete="off">
              <Textbox
                type="email"
                placeholder={user.email}
                className="v-email-override"
                style={{ border: '1px solid #aaa', borderRadius: '4px' }}
                onChange={this.emailChanged.bind(this)}
                onKeyDown={this.submit.bind(this)}
                value={this.state.reportsEmail}
              />
            </form>
            <Button disabled={!total_count} onClick={this.exportCSV.bind(this)} icon={IconExportDoc} white small>
              Export CSV
            </Button>
          </Grid1>
        </Grid>

        <Table
          noScroll
          data={transactions}
          config={getTableConfig.call(this)}
          onClickRow={this.showPayment.bind(this)}
          tableClass="v-table-payment-log"
        />
        <Pagination total_count={total_count} currentPage={page} perPage={per} onChange={this.setPage.bind(this)} />
      </div>
    );
  }
}

export default connect(
  ({ user, userSettings, paymentMethods: { payout_bank_account }, externalBalance, externalTransactions }) => ({
    user,
    userSettings,
    externalBalance,
    transactions: externalTransactions,
    payout_bank_account,
  })
)(ExternalBalances);
